nav {
    grid-area: nav;
    // background-color: white;
    // border: 1px solid #d8d8d8;
    // box-shadow: 1px 1px 4px rgba(0,0,0,0.08);
    // border-radius: 7px;
    padding: 4px;
    padding-right: 2px;
    padding-top: 6px;
    z-index: 90;
}