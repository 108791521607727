footer {
    grid-area: footer;
    width: 100vw;
    font-size: 11.5px;
    color: #cccccc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: red;
    z-index: 100;

    .group {
        display: inline-flex;
        flex-direction: row;
    }

    section {
        margin-left: 6px;
        margin-right: 6px;
    }

    .block{
        display:inline-block;
        padding:30px 50px;
        background:#000;
      }
      .flash-me {
        color:#fff;
        font-size:40px;
        -webkit-animation: flash linear 1.7s infinite;
        animation: flash linear 1.7s infinite;
      }
      
      @-webkit-keyframes flash {
        0% { opacity: 0; } 
        80% { opacity: 1; color:#fff; } 
        83% { opacity: 0; color:#fff; } 
        86% { opacity: 1; color:#fff;}  
        89% { opacity: 0} 
        92% { opacity: 1; color:#fff;} 
        95% { opacity: 0; color:#fff;}
        100% { opacity: 1; color:#fff;}
      }
      @keyframes flash {
        0% { opacity: 0; } 
        80% { opacity: 1; color:#fff; } 
        83% { opacity: 0; color:#fff; } 
        86% { opacity: 1; color:#fff;}  
        89% { opacity: 0} 
        92% { opacity: 1; color:#fff;} 
        95% { opacity: 0; color:#fff;}
        100% { opacity: 1; color:#fff;}
      }
}