@import '~reset-css/sass/reset';

body, html {
    box-sizing: border-box;
    background-color: #ececec;
    overflow: hidden;
}

*:focus {
    outline: none !important;
}

.admin {
    width: 100vw;
    height: 100vh;
    display: grid;
}

.admin.default-grid {
    grid-template-columns: 235px calc(100% - 182px);
    // grid-template-columns: 250px calc(100% - 200px);
    grid-template-rows: 59px calc(100% - 83px) 25px;
    // grid-template-rows: 70px calc(100% - 95px) 25px;
    grid-template-areas: "header header"
                         "nav main"
                         "footer footer"
}

.admin.subactions-grid {
    grid-template-columns: 240px calc(100% - 190px);
    grid-template-rows: 64px 50px calc(100% - 138px) 25px ;
    grid-template-areas: "header header"
                         "nav subactions"
                         "nav main"
                         "footer footer" 
}

.admin.unauthenticated-grid {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas: "main"
                         "main"
}

.admin.light {

    @import './Header.scss';
    @import './Nav.scss';
    @import './Aside.scss';
    @import './Footer.scss';
    @import './Main.scss';
    @import './Subactions.scss';

}

.admin {
    .loading-overlay {
        position: fixed;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.70);
        top: 0;
        left:0 ;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        overflow: none;
    }

    .loading-overlay:before {
        content: '';
        position: fixed;
        z-index: -10;
        top: 0;
        left:0 ;
        right: 0;
        bottom: 0;
        backdrop-filter: grayscale(50%);
    }

    .offline-warning {
        position: fixed;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.50);
        top: 0;
        left:0 ;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        overflow: none;
        flex-direction: column;
    }

    .offline-warning:before {
        content: '';
        position: fixed;
        z-index: -10;
        top: 0;
        left:0 ;
        right: 0;
        bottom: 0;
        backdrop-filter: grayscale(100%);
    }

    .span-link {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: black;
        }
    }

    /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #cccccc #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 29px;
    border: 2px solid #f3f3f3;
  }
}
